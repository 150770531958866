import React, { useEffect, useState } from 'react';

const SpinningCircle = () => {
  const [style, setStyle] = useState(randomPosition());

  useEffect(() => {
    const interval = setInterval(() => {
      setStyle(randomPosition());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function randomPosition() {
    return {
      top: `${Math.random() * 80}%`,
      left: `${Math.random() * 80}%`,
      position: 'absolute',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: 'gray',
    };
  }

  return <div style={style}></div>;
};

export default SpinningCircle;
