import React, { useEffect, useState } from 'react';

const MovingRectangle = ({ number }) => {
  const [style, setStyle] = useState(randomPosition());

  useEffect(() => {
    const interval = setInterval(() => {
      setStyle(randomPosition());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function randomPosition() {
    return {
      top: `${Math.random() * 90}vh`,
      left: `${Math.random() * 90}vw`,
      position: 'absolute',
    };
  }

  return (
    <img 
        src={`${process.env.PUBLIC_URL}/${number}.png`}
        alt={`Image ${number}`}
        style={style}
        width="150"  // you can adjust this value
    />
  );
};

export default MovingRectangle;
